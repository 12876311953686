.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.centeredButton {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.box
{
    margin: 20px;
}

.Contact-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.wipbk {
  background: #F0F2F5;
  background-size: 400% 400%;

  -webkit-animation: GradientBackground 13s ease infinite;
  -moz-animation: GradientBackground 13s ease infinite;
  animation: GradientBackground 13s ease infinite;
}

.pubsbk {
  background: #F0F2F5;
  background-size: 400% 400%;

  -webkit-animation: GradientBackground 13s ease infinite;
  -moz-animation: GradientBackground 13s ease infinite;
  animation: GradientBackground 13s ease infinite;
}

.deepstainbk {
  background: #F0F2F5;
  background-size: 400% 400%;

  -webkit-animation: GradientBackground 13s ease infinite;
  -moz-animation: GradientBackground 13s ease infinite;
  animation: GradientBackground 13s ease infinite;
}

.pubhover:hover {
  background-color: #eb2c80;
  color: 'black'
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.restainbk {
  background: #F0F2F5;
  background-size: 400% 400%;

  -webkit-animation: GradientBackground 13s ease infinite;
  -moz-animation: GradientBackground 13s ease infinite;
  animation: GradientBackground 13s ease infinite;
}

.gradientbk {
  background: #5706BF;
  background-size: 400% 400%;

  -webkit-animation: GradientBackground 5s ease infinite;
  -moz-animation: GradientBackground 5s ease infinite;
  animation: GradientBackground 5s ease infinite;
}

@-webkit-keyframes GradientBackground {
  0%{background-position:62% 0%}
  50%{background-position:39% 100%}
  100%{background-position:62% 0%}
}
@-moz-keyframes GradientBackground {
  0%{background-position:62% 0%}
  50%{background-position:39% 100%}
  100%{background-position:62% 0%}
}
@keyframes GradientBackground {
  0%{background-position:62% 0%}
  50%{background-position:39% 100%}
  100%{background-position:62% 0%}
}